import Slider from '~/themes/base/components/content/Slider'
import eventDefinitions from '@/events/eventDefinitions'

export default {
  extends: Slider,
  data() {
    return {
      sentImpressions: [],
    }
  },
  methods: {
    clicked(banner) {
      this.$eventBus.$emit(eventDefinitions.PROMOTION.CLICK, {
        promotion: {
          id: banner.item_id ?? banner.title,
          name: banner.item_name ?? banner.title,
        },
      })
    },
    sendImpression(banner) {
      if (banner && !this.sentImpressions.includes(banner)) {
        console.log(banner)
        this.sentImpressions.push(banner)
        this.$eventBus.$emit(eventDefinitions.PROMOTION.IMPRESSION, {
          promotion: {
            id: banner.item_id ?? banner.title,
            name: banner.item_name ?? banner.title,
          },
        })
      }
    },
  },
}
